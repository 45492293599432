<template>
  <v-row v-bg:b>
    <v-col cols="12" lg="3" class="mt-1">
      <v-card outlined class="pb-0 mb-0">
        <v-card-text class="text-left text-justify pb-2">
          <p :style="" class="ms-1 mb-0 f-roboto fs-10pt fw-300 text-left">
            Minhas Unidades Curriculares
          </p>
          <v-card outlined class="mt-2 pt-1 pb-0 mb-2">
            <ucsview @selectInitialUc="selectInitialUc" @clickUc="clickUc"/>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="9" class="mt-0">
      <v-card outlined class="p-2 mt-2">
        <planocontainer v-if="uc != null" :ucSel="uc" :key="refresh"/>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import ucsview from "./ucsView.vue"
import planocontainer from "./planoContainer.vue"

export default {
  name: 'minhasucs',
  components: { ucsview, planocontainer },
  data(){
    return {
      ofertas: {},
      ucs: {},
      tab: null,
      items: [ 'Minhas UCs', 'Relatos de experiência' ],
      uc: null,
      refresh: 0,
    }
  },
  computed: {
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');


  },
  methods: {

    selectInitialUc(uc) {
      //console.log("selectInitialUc");
      //console.log(uc);
      this.uc = uc;
      this.refresh++;
    },

    clickUc(uc) {
      //console.log("clickUc");
      //console.log("uc",uc);
      this.uc = uc;
      this.refresh++;
    }

  }
}
</script>

<style scoped>


</style>
