<template>
  <div class="row">
    <div class="col-12 col-lg-12 my-0 pt-2 text-left">
      <div v-if="loading" class="mt-0">
        <span class="fs-8pt ms-2 fw-300">carregando...</span>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mb-6" dense elevation="2" type="list-item-two-line"></v-skeleton-loader>
      </div>
      <v-list dense class="mx-0 px-0 my-0 py-0">
        <v-list-item-group v-model="ucSel" :key="refresh" class="my-0 py-0">
          <v-alert v-if="ucs == null || Object.keys(ucs).lenght == 0" type="info" :color="cor.active.S" class="fs-8pt mt-0 mb-0 py-2">
            Nenhuma UC encontrada!
          </v-alert>
          <div class="px-0 mx-0" v-for="(uc, index) in ucs" :key="index">
            <v-list-item class="px-3 mx-0 pb-0" @click="$emit('clickUc',uc)" style="min-height: 60px; height: 60px">
              <v-list-item-icon class="ps-0 ms-0 me-n1 mt-1">
                <div class="m-0 p-0 rounded" v-if="uc.oferta2021S2">
                  <i v-i:duo#star#14 class="absolute" :style="'top: -1px; right: 31px; color: '+hexShades('#ffe600',0.5)"></i>
                  <div class="absolute mt-0 pt-0" style="top: 2px; right: 4px; ">
                    <span class="fs-8pt fw-500 py-0 px-1">
                      <span class="fw-600 f-roboto" :style="'color: '+hexShades('#ffe600',0.5)">21.2</span>
                    </span>
                  </div>
                </div>
                <div class="mt-2">
                  <i v-i:duo#graduation-cap#20 class="btn-icon-left me-2"></i>
                </div>
                <div class="absolute" style="top: 34px; left: 14px;">
                  <span v-for="(area, key, index) in uc.areas" :key="key">
                    <tagarea class="me-1" :sigla="area.sigla"/>
                  </span>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="ms-0 mt-2 pt-0">
                <v-list-item-title class="ms-0 mt-0 pt-0 text-left f-roboto fw-600 fs-9pt f-raleway">
                  {{uc.nome}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="m-0 p-0"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import tagarea from "@/components/tagArea.vue"

export default {
  name: "ucsView",
  components: { tagarea },
  props: [],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      ucSel: "",
      ucs: null,
      refresh: 0,
      loading: false,
    }
  },
  watch: {

  },
  mounted: function() {
    var self = this;
    this.buildUCs();
  },
  methods:{

    buildUCs() {
      var self = this;
      var first = true;
      self.loading = true;
      //console.log("buildUCs para "+this.userLog.nome);
      //console.log("this.userLog",this.userLog);
      rdb.ref('/curriculo/ofertas/2021S2/').orderByChild("profID").equalTo(this.userLog.id).on('value', function(snapshot) {
        self.ofertas = snapshot.val();
        //console.log("self.ofertas",self.ofertas);
        if(self.ofertas == null) {
          self.ucs = null;
          self.refresh++;
          self.loading = false;
          return;
        }
        self.ucs = {};
        for(var key in self.ofertas) {
          rdb.ref('/curriculo/ucs/'+self.ofertas[key].ucID).on('value', function(snapshot) {
            var uc = snapshot.val();
            self.ucs[uc.id] = uc;
            //console.log("uc",uc);
            if(first) {
              first = false;
              self.$emit("selectInitialUc",uc);
              self.loading = false;
            }
            self.refresh++;
          });
        }
      });
    }

  }
}
</script>

<style scoped>

</style>
